const API_URL = process.env.API_URL

export default {
	uriLogin() {
		return `${API_URL}/auth/login`
	},

	uriAnalytics(id = "") {
		return `${API_URL}/statistics/dashboard${id}`
	},

	uriDashboard(id = "") {
		return `${API_URL}/statistics/business/${id}`
	},

	uriLogs() {
		return `${API_URL}/statistics/logs`
	},

	uriLogout() {
		return `${API_URL}/auth/logout`
	},

	uriStaff(id = "") {
		return `${API_URL}/staff/${id}`
	},

	uriRecoverPassword(id = "") {
		return `${API_URL}/staff/recover-password/${id}`
	},

	uriStaffPassword() {
		return `${API_URL}/staff/password`
	},

	uriStaffEmail() {
		return `${API_URL}/staff/email`
	},

	uriPages(id = "") {
		return `${API_URL}/pages/${id}`
	},

	uriEmailTemplate(id = "") {
		return `${API_URL}/email-templates/${id}`
	},

	uriCountries(id = "") {
		return `${API_URL}/countries/${id}`
	},

	uriBusinesses(id = "") {
		return `${API_URL}/businesses/${id}`
	},

	uriContracts(id = "") {
		return `${API_URL}/contracts/${id}`
	},

	uriBusinessContract(businessId = "", contractId = "") {
		return `${API_URL}/businesses/${businessId}/contracts/${contractId}`
	},

	uriOrders(id = "") {
		return `${API_URL}/orders/${id}`
	},
	uriUserFromClientId(id = "") {
		return `${API_URL}/orders/user/${id}`
	},
	uriCreateNewOrder() {
		return `${API_URL}/orders/create-new-order`
	},
	uriOrdersToAggregate() {
		return `${API_URL}/orders/toAggregation`
	},
	uriVehicles(id = "") {
		return `${API_URL}/vehicles/${id}`
	},
	uriRoutes(id = "") {
		return `${API_URL}/routes/${id}`
	},
	uriRoute4meSync() {
		return `${API_URL}/routes/route4meSync`
	},
	uriAggregation() {
		return `${API_URL}/orders/aggregation`
	},
	uriGrids(id = "") {
		return `${API_URL}/grids/${id}`
	},
	uriShifts(id = "") {
		return `${API_URL}/shifts/${id}`
	},
	uriContractTypes(id = "") {
		return `${API_URL}/contract-types/${id}`
	}
}
